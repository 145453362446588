html,
body {
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
}
body {
    position: relative;
    margin: 0;
    max-width: 100vw;
    font-family: -apple-system, BlinkMacSystemFont, 'Crimson Text', 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.grecaptcha-badge {
    visibility: hidden;
}
