/*

Vanilla Template

https://templatemo.com/tm-526-vanilla

*/

p {
    font-size: 20px;
    line-height: 30px;
}

section {
    padding: 25px 0 35px;
    background: #fff;
}

body {
    position: relative; /* we need this for the scrollspy */
    font-size: 14px;
}

a {
    color: #3cc;
}

a:hover {
    color: #ff0;
}

/*----------
  NAV
----------*/

.fixed-side-navbar {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 99999;
    margin-top: -100px;
    text-align: right;
    padding: 30px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.fixed-side-navbar:hover {
    background: transparent;
}
.fixed-side-navbar:hover .nav > li > a > span {
    color: rgba(0, 0, 0, 0.5);
    display: block;
    background-color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 15px;
}
.fixed-side-navbar .nav > li a.active {
    background-color: transparent;
    color: black;
}
.fixed-side-navbar .nav > li a.active:after {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}
.fixed-side-navbar .nav > li a.active:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 17px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 10px;
}
.nav > li {
    position: relative;
    display: block;
}
.fixed-side-navbar .nav > li a {
    color: #fff;
    min-height: 32px;
    background: transparent;
    padding: 5px 45px 5px 25px;
    border-right: none;
}
.fixed-side-navbar .nav > li a span {
    display: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
.fixed-side-navbar .nav > li a:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: rgba(250, 250, 250, 0.75);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
}
.fixed-side-navbar .nav > li a:hover {
    background-color: transparent;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-color: black;
    border-right: 0;
}
.fixed-side-navbar .nav > li a:hover > span {
    color: black;
    display: block;
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.primary-button a {
    display: inline-block;
    background-color: #ff7d27;
    padding: 15px 24px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.parallax-content {
    width: 100%;
    min-height: 100vh;
    background-size: cover;
}

.banner-content {
    padding-top: 30vh;
    text-align: center;
    /* background-image: url(/img/TreesBackground.jpg); */
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(/img/HummingbirdLanding-max.jpg);
}

.banner-content h1 {
    margin-top: 0px;
    font-size: 100px;
    color: #fff;
    font-weight: 900;
    /* text-transform: uppercase; */
    margin-bottom: 20px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.banner-content h2 {
    color: #fff;
    font-size: 35px;
    font-weight: 300;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.banner-content em {
    color: #ff7d27;
    font-weight: 600;
    font-style: normal;
}

.banner-content span {
    display: inline-block;
    margin-top: -20px;
    font-weight: 300;
    font-size: 46px;
    color: #fff;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.banner-content .primary-button {
    margin-top: 15px;
}

.service-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15vh;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(/img/SunsetPose-max.jpg);
    background-attachment: fixed;
    min-height: 100vh;
    background-size: 150%;
    background-position: 0% 0%;
}

.service-content .left-text h4 {
    font-size: 36px;
    font-weight: 500;
    color: #fff;
}

h4,
.h4 {
    font-size: 18px;
}

.service-content .left-text .line-dec {
    width: 45px;
    height: 3px;
    background-color: #ff7d27;
    margin: 20px 0px 20px 0px;
}

.service-content .left-text p {
    color: #fff;
}

.service-content .left-text ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
}

.service-content .left-text ul li {
    margin: 15px 0px;
    font-weight: 600;
    color: #fff;
}

.service-content .left-text .primary-button {
    margin: 30px 0px;
}

.service-content .service-item {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px 30px;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    border-radius: 10px;
}

.service-content .service-item h4 {
    font-size: 2rem;
}

.service-content .service-item .line-dec {
    width: 45px;
    height: 3px;
    background-color: #ff7d27;
    margin: 20px auto 15px auto;
}

.projects-content {
    padding-top: 20vh;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(/img/TributeToClarence-max.jpg);
}

.projects-content .item {
    overflow: hidden;
    position: relative;
}

.projects-content .item img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    transition: transform 0.5s ease-out, filter 0.5s ease-out;
}

.projects-content .item:hover img {
    transform: scale(1.05);
    filter: brightness(50%);
}

.projects-content .item .hover-over-text {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease-out;
    position: absolute;
    margin: 0 auto;
    padding: 20px;
    top: 30%;
    text-align: center;
}

.projects-content .item .hover-over-text h2 {
    font-size: 30px;
}

.projects-content .item:hover .hover-over-text {
    visibility: visible;
    color: #fff;
    opacity: 0.8;
}

.projects-content .item .divider-bar-orange {
    height: 5px;
    width: 100%;
    background-color: #ff7d27;
}

.projects-content .item .divider-bar-black {
    height: 3px;
    width: 33%;
    background-color: #232323;
    margin: -10px auto 10px;
}

.projects-content .item {
    margin: 15px;
    border: 3px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.projects-content .item .text-content {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    height: 125px;
}

.projects-content .item .text-content h1 {
    margin-top: -5px;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
}

.projects-content .item .text-content h2 {
    margin-bottom: 10px;
}

.projects-content .item .text-content span {
    font-style: normal;
    font-size: 22px;
    font-weight: 700;
    color: #ff7d27;
}

.owl-pagination {
    margin-top: 40px;
    opacity: 1;
    display: inline-block;
}

.owl-page span {
    display: block;
    width: 14px;
    height: 14px;
    margin: 0px 5px;
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #fff;
}

.tabs-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8vh;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(/img/TributeToClarence-max.jpg);
    background-attachment: fixed;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
}

section {
    background-color: transparent;
    padding-bottom: 15px;
}

.wrapper {
    text-align: center;
}

.tabs {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.tabs li {
    display: inline-block;
    text-align: center;
    margin: 10px;
}

.tabs a {
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 15px;
    border: 4px solid #fff;
}

.tabs a:hover {
    color: #ff7d27;
}

.tabs .active {
    border: 4px solid #ff7d27;
}

.tabgroup div {
}

.tabgroup p {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 25px;
}

.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

.contact-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 16vh;
    /* background-image: url(/img/5th-section.jpg); */
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(/img/TreesBackground-max.jpg);
    background-size: cover !important;
    background-position: center center !important;
}

.contact-form {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px 25px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.contact-form p {
    color: white;
    text-align: center;
    margin-top: 20px;
    font-size: 10px;
}

.contact-form h1 {
    color: white;
    text-align: center;
    margin: 20px;
}

.contact-form .row {
    justify-content: center;
}

#contact input {
    border-radius: 3px;
    padding-left: 15px;
    font-size: 20px;
    color: #232323;
    background-color: rgba(250, 250, 250, 1);
    outline: none;
    border: none;
    box-shadow: none;
    line-height: 50px;
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
}

#contact textarea {
    border-radius: 3px;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 20px;
    color: #232323;
    background-color: rgba(250, 250, 250, 1);
    outline: none;
    border: none;
    box-shadow: none;
    height: 165px;
    max-height: 220px;
    width: 100%;
    margin-bottom: 25px;
}

.button-enabled {
    display: inline-block;
    background-color: #ff7d27;
    padding: 15px 24px;
    width: 100%;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.button-disabled {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px 24px;
    width: 100%;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.invalid {
    border: 1px solid red !important;
    background-color: #fda49a !important;
}

.request-resume {
    margin-bottom: 30px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px 20px;
    border-radius: 10px;
}

.request-resume h1 {
    color: white;
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
}

.request-resume div {
    margin: 0 auto;
}

.request-resume a button {
    display: block;
    margin: 0 auto;
    width: 75%;
    height: 50px;
    background-color: #ff7d27;
    padding: 0px 24px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.request-resume button {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 50px;
    background-color: #ff7d27;
    padding: 0px 24px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.map {
    margin-bottom: 30px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px 20px;
}

footer {
    text-align: center;
    background-color: #282b2f;
    padding: 70px 0px;
}

footer .primary-button {
    margin-bottom: 30px;
}

footer ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

footer ul li {
    display: inline-block;
    margin: 0px 4px;
}

footer ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    background-color: #ff7d27;
    color: #282b2f;
    border-radius: 50%;
    text-decoration: none;
    transition: all 0.5s;
    font-size: 17px;
}

footer ul li a:hover {
    color: #282b2f;
    background-color: #ff8e43;
}

footer p {
    font-size: 24px;
    color: #fff;
    margin-top: 15px;
    color: #fff;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}

footer em {
    color: #ff8e43;
    font-weight: 600;
    font-style: normal;
}

@media (max-width: 768px) {
    .banner-content {
        padding-top: 30vh;
        background-image: url(/img/HummingbirdLanding-min.jpg);
    }

    .container {
        width: 90% !important;
    }

    .banner-content h1 {
        font-size: 32px;
    }

    .banner-content h2 {
        font-size: 20px;
    }

    .banner-content em {
    }

    .banner-content span {
        font-size: 24px;
    }

    .banner-content .primary-button {
        margin-top: 15px;
    }

    .primary-button a {
        font-size: 14px;
    }

    .service-content {
        padding-top: 5vh;
        text-align: center;
        background-size: cover;
        background-position: 75% 0%;
        background-image: url(/img/SunsetPose-min.jpg);
    }

    .service-content .left-text {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px;
        margin-bottom: 30px;
    }

    .service-content .left-text h4 {
        font-size: 24px;
    }

    .service-content .left-text p {
        font-size: 16px;
        line-height: 24px;
    }

    .service-content .left-text .line-dec {
        width: 45px;
        height: 3px;
        background-color: #ff7d27;
        margin: 20px auto 20px auto;
    }

    .service-content .left-text ul {
        text-align: left;
    }

    .service-content .service-item {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 25px 30px;
        text-align: center;
        color: #fff;
        margin-bottom: 30px;
    }

    .service-content .service-item h4 {
        font-size: 1.7rem;
    }

    .service-content .service-item p {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .projects-content {
        background-position: center center !important;
        background-image: url(/img/TributeToClarence-min.jpg);
    }

    .projects-content .item img {
        height: 300px;
    }

    .projects-content .item .text-content h1 {
        font-size: 20px;
    }

    .projects-content .item .text-content span {
        font-size: 16px;
    }

    .projects-content .item .text-content h2 {
        font-size: 1.6rem;
    }

    .projects-content .item .text-content {
        height: 175px;
    }

    .contact-content {
        background-image: url(/img/TreesBackground-min.jpg);
    }

    .contact-form h1 {
        font-size: 1rem;
    }

    .contact-form p {
        font-size: 8px;
        line-height: 14px;
    }

    #contact input {
        font-size: 15px;
    }

    #contact textarea {
        font-size: 15px;
    }

    .request-resume h1 {
        font-size: 1.2rem;
    }

    .request-resume div {
        color: rgb(92, 92, 255);
        font-size: 1.2rem;
        text-align: center;
    }

    footer p {
        font-size: 12px;
    }

    .button-disabled,
    .button-enabled {
        font-size: 14px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
    .banner-content {
        background-image: url(/img/HummingbirdLanding-med.jpg);
    }
}

@media (min-width: 1439px) {
    .container {
        max-width: 1350px !important;
    }
    .banner-content {
        background-image: url(/img/HummingbirdLanding-med.jpg);
    }
}

@media (min-width: 2500px) {
    .container {
        max-width: 1800px !important;
    }
    .banner-content {
        background-image: url(/img/HummingbirdLanding-max.jpg);
    }
}

/* LIGHT BOX */

body:after {
    content: url(/img/close.png) url(/img/loading.gif) url(/img/prev.png)
        url(/img/next.png);
    display: none;
}

body.lb-disable-scrolling {
    overflow: hidden;
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    display: none;
}

.lightbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;

    /* Image border */
    border: 4px solid white;
}

.lightbox a img {
    border: none;
}

.lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;

    /* Background color behind image.
     This is visible during transitions. */
    background-color: white;
}

.lb-outerContainer:after {
    content: '';
    display: table;
    clear: both;
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(/img/loading.gif) no-repeat;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.lb-container > .nav {
    left: 0;
}

.lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(/img/prev.png) left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(/img/next.png) right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
    content: '';
    display: table;
    clear: both;
}

.lb-data {
    padding: 0 4px;
    color: #ccc;
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
}

.lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
}

.lb-data .lb-caption a {
    color: #4ae;
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(/img/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}
